import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/SEO";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Template({ data, location }) {
    const { markdownRemark: post } = data;

    const featuredImg =
        post.frontmatter.featured?.childImageSharp.gatsbyImageData;

    return (
        <div className="prose dark:prose-light mx-auto py-24 px-4 font-blog">
            <Seo title={post.frontmatter.title} />
            <StructuredData
                article={post.frontmatter}
                siteUrl={data.site.siteMetadata.siteUrl}
            />
            <h1>{post.frontmatter.title}</h1>
            <time className="block pb-2">{post.frontmatter.date}</time>
            {featuredImg && (
                <GatsbyImage
                    image={featuredImg}
                    alt="featured image about blog post"
                />
            )}
            <article dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
    );
}

const StructuredData = ({ article, siteUrl }) => {
    return (
        <Helmet title="Structured Data Example">
            {(article ? true : null) && (
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BlogPosting",
                        mainEntityOfPage: {
                            "@type": "WebPage",
                            "@id": article.path,
                        },
                        headline: article.title,
                        description: `Un article absolument incroyable !`,
                        image: article.featured?.childImageSharp
                            ?.gatsbyImageData
                            ? `${siteUrl}/${article.featured?.childImageSharp.gatsbyImageData}`
                            : null,
                        author: {
                            "@type": "Organization",
                            name: `Arthur Dubois`,
                            url: siteUrl,
                        },
                        publisher: {
                            "@type": "Organization",
                            name: `Arthur Dubois`,
                            logo: {
                                "@type": "ImageObject",
                                url: `${siteUrl}/logo.svg`,
                            },
                        },
                        datePublished: article.date,
                        dateModified: article.date,
                    })}
                </script>
            )}
        </Helmet>
    );
};

export const pageQuery = graphql`
    query BlogPostByPath($path: String!) {
        site {
            siteMetadata {
                siteUrl
            }
        }
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
                featured {
                    childImageSharp {
                        gatsbyImageData(width: 300, placeholder: BLURRED)
                    }
                }
            }
        }
    }
`;
